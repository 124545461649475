<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Category Tag"/>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
              <v-btn 
                v-show="canDo && canDo.can.includes('add')"
                class="mx-2" depressed dark color="primary" v-on="on">
                <v-icon left dark>mdi-plus-circle</v-icon> Add
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="form_valid" ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field v-model="editedItem.name" :rules="validate_rules.name" label="Tag Name"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn v-if="canDo && canDo.can.includes('add')" color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
      <v-row justify="center">
        <v-col cols="9">
          <v-toolbar class="search-tools pa-0 mb-4" flat>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="5" sm="5">
              <v-text-field
                label="Search"
                v-model="search"
                dense
                hide-details
                prepend-inner-icon="mdi-magnify"
                class="hidden-sm-and-down"
              /> 
            </v-col>
          </v-toolbar>
          <v-data-table 
              :items="allCategories" 
              :headers="headers"
              sort-by="id"
              :search="search"
              :sort-asc="true"
          >
            <template #item.actions="{item}">
                <!-- <v-btn 
                  v-if="canDo && (canDo.can.includes('detail') || canDo.can.includes('update'))"
                  class="ma-1" outlined color="primary" icon tile small @click="editItem(item)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn> -->
                <v-btn 
                  v-if="canDo && canDo.can.includes('delete')"
                  class="ma-1" outlined color="red" icon tile small @click="deleteItem(item)">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
            </template>
            <template #item.employee_count="{value}">
                <span>{{ value | comma }}</span>
            </template>
            <!-- <template #item.updatedAt="{value}">
                <span>{{moment(value).format('DD-MM-YYYY HH:MM:SS')}}</span>
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';

export default {
  name: 'categories',
  components: {
    pageHeading
  },
  data: () => ({
    dialog: false,
    search : '',
    editedIndex: -1,
    editedItem: {
      id: -1,
      name: '',
    },
    defaultItem: {
      id: -1,
      name: '',
    },
    form_valid : false,
    validate_rules : {
      name: [
        v => !!v || 'Name is required',
      ]
    }
  }),
  methods: {
    ...mapActions(['genCan','getCategories','updateCategory','createCategory','deleteCategory']),

    addItem(){
      // this.$refs.form.resetValidation();
      this.dialog = true;
    },
    
    editItem (item) {
      this.editedIndex = this.allCategories.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.unit = this.editedItem.is_percent ? '%' : 'Bht.';
      this.dialog = true
    },

    deleteItem (item) {
        confirm('Are you sure you want to delete this tag?') && this.deleteCategory(item);
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },

    save () {
      if(this.form_valid){
        if (this.editedIndex > -1) {
          this.updateCategory(this.editedItem);
        } else {
          this.createCategory(this.editedItem);
        }
        this.close()
      }
    },
  },
  computed: {
    headers(){
      var columns = [
          {text: "ID", value: 'id', width: '15%'},
          {text: "Tag Name", value: 'name', width: '50%'},
          {text: "Driver Amount", value: 'employee_count',align: 'center', width: '15%'},
        //   {text: "Updated At", value: 'updatedAt'},
          {text: "", value: 'actions' , sortable: false, width: '20%', align: 'right'},
        ];
      if(this.canDo && (this.canDo.can.includes('detail') || this.canDo.can.includes('update') || this.canDo.can.includes('delete'))){
        return columns;
      } else {
        return columns.filter(column => column.value != 'actions');
      }
    },
    formTitle () {
        return this.editedIndex === -1 ? 'New Category' : 'Edit Category'
      },
    ...mapGetters(['canDo','allCategories'])
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  created() {
    this.genCan();
    this.getCategories();
  }
};
</script>